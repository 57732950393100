.header {
  width: 90%;
  display: flex;
  height: 100px;
  margin: auto;
  align-items: center;
}
.header-box {
  width: 200px;
  display: flex;
  justify-content: space-between;
}
.header-box {
  cursor: pointer;
}
.colored {
  color: #fa531e;
}
.sectionRow {
  display: flex;
  padding: 20px 5%;
  padding-right: 0;
  position: relative;
  justify-content: space-between;
}
.sectionColumn {
  width: 55%;
  padding: 50px 0;
}
.sectionColumn2 {
  width: 45%;
}
.estimate-head {
  font-size: 55px;
  font-weight: 600 !important;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.estimate-heading {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 400;
}
.estimate-price {
  font-size: 50px;
  color: #fa531e;
  font-weight: bolder;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.estimate-saving {
  font-size: 25px;
  margin-top: 20px;
  color: #000929;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 15px;
}
.saving {
  color: #fa531e;
  font-size: 30px;
}
.price-info {
  font-size: 15px;
  font-weight: 300;
}
.textBold {
  font-weight: 700;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.estimate-description {
  width: 580px;
  font-size: 20px;
  color: #000929;
  font-weight: 200;
  line-height: 33px;
}
.breakdown-small-text {
  font-size: 15px;
  color: #fa531e;
  margin-bottom: 10px;
}
.breakdown-text {
  font-size: 50px;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.solar-estimate {
  font-size: 16px;
}
.breakdown-box {
  display: flex;
  margin: 200px 0;
  padding: 50px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fdece7;
}
.estimate-row {
  width: 1250px;
  display: flex;
  padding: 45px;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  justify-content: space-between;
}
.estimate-card {
  width: 32%;
  display: flex;
  padding: 30px 10px;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.breakdown-info {
  color: #6c727f;
  font-size: 15px;
  text-align: center;
}
.estimateIcon {
  margin-bottom: 25px;
  height: 90px;
  width: 90px;
}
.dashboard-box {
  width: 90%;
  padding: 40px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  align-items: center;
  background: #fef9f8;
  border-radius: 10.6667px;
  border: 2px solid #fdece7;
  justify-content: space-between;
}
.dashboard-testimonial-box {
  display: flex;
  margin-top: 100px;
  margin-bottom: 200px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.testimonial-box {
  display: flex;
  margin: 200px 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.testimonial-h1 {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.testimonial-p {
  color: #000929;
  width: 500px;
  margin-top: 1 0px;
  margin-bottom: 40px;
  font-weight: 200 !important;
  font-size: 21px;
  opacity: 0.7;
  letter-spacing: 1px;
}
.testimonial-h4 {
  color: #000929;
  width: 810px;
  line-height: 34px;
  font-size: 24px;
  font-weight: 300;
}
.home-box {
  width: 90%;
  padding: 50px;
  display: flex;
  margin: 100px auto;
  margin-bottom: 0;
  align-items: center;
  background: #fef9f8;
  border-radius: 10.6667px;
  border: 2px solid #fdece7;
  justify-content: space-between;
}
.houseIcon {
  width: 40%;
}
.dashboard-col {
  width: 60%;
}
.home-box-row {
  display: flex;
  width: 650px;
  margin-top: 40px;
  align-items: flex-start;
  justify-content: space-between;
}
.home-box-col {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-box-icon {
  margin-bottom: 15px;
  height: 70px;
  width: 70px;
}
.home-box-col h3 {
  color: #000929;
  font-size: 25px;
  text-align: center;
}
.home-box-heading {
  color: #100a55;
  line-height: 136%;
  font-size: 55px;
  margin-bottom: 40px;
  letter-spacing: -0.01em;
  width: 700px;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.home-box-info {
  color: #6c727f;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21.3333px;
  line-height: 160%;
}
.home-info-row {
  display: flex;
  align-items: flex-start;
}
.home-info-row p {
  width: 80%;
  color: #6c727f;
  font-weight: 300;
  font-size: 21.3333px;
  line-height: 130%;
  margin-left: 10px;
}
.doneIcon {
  margin-top: 5px;
  font-size: 10px;
  color: #fa531e;
}
.contact-box {
  min-height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f9b8a4;
}
.contact-promise {
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  opacity: 0.8;
  margin: 0;
  margin-bottom: 10px;
}
.contact-heading {
  letter-spacing: -0.01em;
  font-size: 45px;
  line-height: 140%;
  color: #ffffff;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}
.contact-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
}
.contact-info {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
}
.contact-input-box {
  position: relative;
  width: 600px;
  height: 70px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}
.contact-input-box input {
  padding: 10px 30px;
  width: 600px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 40px 40px rgba(90, 90, 90, 0.1);
  border-radius: 10px;
  background-color: white;
  border: none;
}
.contact-input-box button {
  position: absolute;
  right: 10px;
  width: 130px;
  height: 54px;
  border: none;
  color: white;
  background: #000929;
  border-radius: 6px;
}
.contact-small-info {
  font-size: 16px;
  color: #9ea3ae;
}
.logo-header {
  width: 180px;
  cursor: pointer;
  object-fit: contain;
}
.logo-footer {
  width: 180px;
  object-fit: contain;
}
.footerBox {
  display: flex;
  background-color: #fdece7;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: space-around;
}
.footer-row {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icons-row {
  width: 180px;
  display: flex;
  align-items: center;
}
.footer-icon {
  margin: 0 15px;
  cursor: pointer;
  color: "#06102F";
  font-size: 30px !important;
}
.copyText {
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  background-color: #fdece7;
}
.copyText p {
  opacity: 0.5;
  color: #060f2e;
  line-height: 160%;
}
.mapFrame {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.addressBar {
  position: relative;
  z-index: 10;
  width: 110%;
  padding: 20px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: 0 0 35px -20px black;
}
.addressBarIcon {
  color: #fa531e;
  margin: 0 20px;
  font-size: 34px !important;
}
.addressBar span {
  font-size: 20px;
}
.nearText {
  font-size: 13px;
}
.solarPro-button {
  padding: 10px 30px 10px 15px !important;
  border-radius: 100px !important;
  margin-top: 20px !important;
  background: #f6531e !important;
  font-size: 18px !important;
  text-transform: none !important;
}
.butwrapper {
  text-align: center;
}
.KeyboardVoice {
  width: 45px;
  height: 45px;
  margin-right: 5px;
}
.scrollButton {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 100px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  margin: 0 auto;
  box-shadow: 0 0 8px rgba(220, 220, 220);
  background-color: white;
}
.scrollButton span {
  margin-left: 10px;
}
.dashboard-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.solarPro-button-main {
  margin: 0 auto;
  font-size: 20px !important;
  padding: 25px 35px !important;
  text-transform: none !important;
  border-radius: 100px !important;
  background: #f48967 !important;
}
/* for laptop screens */
@media only screen and (max-width: 1250px) {
  .estimate-row {
    width: 100%;
    padding: 45px 25px;
  }
  .estimate-description {
    width: 95%;
  }
  .home-box-heading {
    width: 95%;
    font-size: 45px;
    line-height: 126%;
    margin-bottom: 20px;
    letter-spacing: -0.01em;
  }
  .home-box-row {
    width: 100%;
    margin-top: 40px;
  }
  .home-box-icon {
    margin-bottom: 15px;
    height: 60px;
    width: 60px;
  }
  .home-box-col h3 {
    color: #000929;
    font-size: 20px;
    margin: 0;
  }
  .breakdown-info {
    color: #6c727f;
    font-size: 14px;
    text-align: center;
  }
}

/* for small screens */
@media only screen and (max-width: 1025px) {
  .estimate-row {
    width: 100%;
    padding: 45px;
    flex-direction: column !important;
  }
  .estimate-card {
    width: 90%;
    padding: 30px;
    margin-bottom: 30px;
  }
  .home-box-heading {
    width: 95%;
    font-size: 50px;
    line-height: 126%;
    margin-bottom: 20px;
    letter-spacing: -0.01em;
  }
  .home-box-row {
    width: 100%;
    margin-top: 40px;
  }
  .home-box-icon {
    margin-bottom: 15px;
    height: 60px;
    width: 60px;
  }
  .home-box-col h3 {
    color: #000929;
    font-size: 17px;
    margin: 0;
  }
  .breakdown-info {
    color: #6c727f;
    font-size: 14px;
  }
  .home-box-icon {
    margin-bottom: 15px;
    height: 90px;
    width: 90px;
  }
  .dashboard-box,
  .home-box {
    flex-direction: column-reverse;
  }
  .houseIcon {
    width: 60%;
  }
  .dashboard-col {
    width: 100%;
    margin-top: 50px;
  }
  .home-box-col {
    width: 30%;
  }
  .breakdown-info {
    width: 100%;
    text-align: center;
  }
  .solarPro-button-main {
    margin: 0 auto;
    font-size: 18px !important;
    padding: 12px 45px !important;
    text-transform: none !important;
    border-radius: 100px !important;
    background: #f48967 !important;
  }
  .testimonial-h4 {
    width: 90%;
    line-height: 36px;
  }
  .footerBox {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  .copyText {
    padding: 20px;
  }
  .copyText p {
    line-height: 120%;
  }
  .estimateIcon {
    margin-bottom: 15px;
    height: 90px;
    width: 90px;
  }
  .breakdown-small-text {
    font-size: 15px;
  }
  .sectionRow {
    padding: 20px 5%;
    padding-right: 5%;
    flex-direction: column;
    justify-content: space-between;
  }
  .sectionColumn {
    width: 100%;
    padding: 100px 0;
    padding-bottom: 10px;
  }
  .sectionColumn2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .addressBar {
    width: 100%;
    margin-top: 30px;
  }
  .mapFrame {
    width: 100%;
    height: 400px;
    border-radius: 0;
  }
  .solarPro-button {
    padding: 15px 30px !important;
    border-radius: 100px !important;
    background: #f6531e !important;
    position: relative !important;
    font-size: 14px !important;
    text-transform: none !important;
    right: 0;
    bottom: 0;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }
  .breakdown-box {
    margin: 100px 0;
  }
  .contact-heading {
    letter-spacing: -0.01em;
    font-size: 25px;
    line-height: 140%;
    color: #ffffff;
    font-family: "IBM Plex Sans Thai Looped", sans-serif;
  }
  .contact-info {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    text-align: center;
  }
  .contact-box {
    min-height: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f9b8a4;
    padding: 30px;
  }
}

/* for tables */
@media only screen and (max-width: 609px) {
  .dashboard-box,
  .home-box {
    padding: 20px;
  }
  .testimonial-h1 {
    font-size: 35px;
  }
  .testimonial-p {
    width: 90%;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .testimonial-h4 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .dashboard-testimonial-box {
    margin-bottom: 100px;
  }
  .home-box-icon {
    margin-bottom: 15px;
    height: 60px;
    width: 60px;
  }
  .mapFrame {
    width: 100%;
    height: 300px;
    border-radius: 0;
  }
  .estimate-row {
    padding: 15px;
  }
  .estimate-card {
    width: 95%;
    padding: 30px;
    margin-bottom: 30px;
  }
  .estimate-head {
    font-size: 35px;
  }
  .estimate-heading {
    font-size: 16px;
  }
  .estimate-price {
    font-size: 45px;
  }
  .price-info {
    font-size: 13px;
  }
  .estimate-description {
    font-size: 16px;
    line-height: 23px;
  }
  .addressBar {
    width: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 10px -7px black;
  }
  .addressBarIcon {
    color: #fa531e;
    margin: 0 2px;
    margin-left: 0;
    font-size: 24px !important;
  }
  .addressBar span {
    font-size: 14px;
  }
  .home-box-heading {
    font-size: 34px;
    width: 100%;
  }
  .home-info-row p {
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    margin-left: 10px;
  }
}

/* for mobile */
@media only screen and (max-width: 481px) {
  .solarPro-button-main {
    font-size: 16px !important;
    padding: 10px 30px !important;
  }
  .solarPro-button {
    font-size: 14px !important;
    padding: 7px 20px !important;
  }
  .sectionColumn {
    padding-top: 0;
  }
  .estimate-head {
    font-size: 30px;
  }
  .footerBox {
    width: 100%;
    padding: 5%;
    height: 230px;
    padding-top: 20px;
    align-items: flex-start;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: space-around;
  }
  .footer-row {
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .footer-row span {
    font-size: 14px;
  }
  .icons-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .footer-icon {
    margin: 0 15px;
    margin-left: 0;
    color: "#06102F";
    font-size: 30px !important;
  }
}
