* {
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;400;700&family=Inter:wght@100;400;700&display=swap");
:root {
  --font-family: "Inter", sans-serif;
  --font-family-Encode: "Encode Sans Expanded", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #2baaee 1.84%, #2beee0 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #2baaee -13.86%, #2beee0 99.55%);
  --gradient-footer: linear-gradient(
    103.22deg,
    #2bee34 -13.86%,
    #2beee0 99.55%
  );

  --color-bg: #ffffff;
  /* --color-footer: #0a1414; */
  --color-footer: linear-gradient(89.97deg, #2baaee 1.84%, #2beee0 102.67%);
  --color-blog: #042c54;
  --color-text: #212121;
  --color-f: #ff1919;
  --color-subtext: #ffffff;
  --color-box: #e3f9fe;
}
