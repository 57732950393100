:root {
  --blue: #f9b8a4;
  --cardColor: #ffffff;
  --backgroundColor: #fde0d8;
  --cardSecondaryColor: #f48967;
  /* --cardSecondaryColor: #d7b893; */
  --buttonColor: rgba(141, 112, 110, 0.47);
  --sliderSecondary: #6a5555;
  --sliderPrimary: rgba(141, 112, 110, 0.47);
}

#services {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden !important;
  background-color: var(--backgroundColor);
}
.card-box {
  z-index: 10;
  width: 800px;
  margin: auto;
  height: 600px;
  padding: 20px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--cardColor);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.card-about {
  font-size: 22px;
  text-align: center;
  color: var(--buttonColor);
  margin-top: 12px !important;
}
.card-button {
  width: 200px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  font-family: "Nunito Sans", sans-serif;
  background-color: var(--buttonColor) !important;
}
.borderTop {
  top: 0;
  position: absolute;
  width: 100%;
  height: 300px;
}
.borderBottom {
  bottom: 0;
  rotate: 180deg;
  position: absolute;
  width: 100%;
  height: 300px;
}
.home-img-row {
  width: 70%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
}
.home-img-box {
  margin: 10px;
  width: 210px;
  height: 210px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: 500px;
  flex-direction: column;
  justify-content: center;
  border: #f9b8a4 solid 5px;
  /* background-color: rgb(39, 17, 17); */
}
.home-img {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
}
.home-img2-row {
  width: 90%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.home-img2-box {
  width: 150px;
  height: 150px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: 500px;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: #f48967 solid 5px;
}
.home-box-text {
  bottom: -34px;
  position: absolute;
  text-align: center;
}
.home-img3-row {
  width: 90%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.home-img3-box {
  width: 30%;
  height: 190px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition-duration: 400ms;
  border: 2px solid rgb(155, 152, 152);
}
.input {
  width: 100%;
  border-radius: 100px;
  background-color: #f9b8a4;
  border: none;
  padding-left: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
}
.CircularProgressBox {
  padding: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggestion-item {
  padding: 10px;
  margin: 0 15px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 0 2px gainsboro;
}
iframe {
  width: 70%;
  height: 300px;
  border-radius: 20px;
}

@media only screen and (max-width: 840px) {
  .card-box {
    width: 95% !important;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  }
  .card-about {
    font-size: 12px;
    text-align: center;
    color: var(--buttonColor);
    margin-top: 12px !important;
  }
  .card-button {
    width: 200px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-transform: none;
    transition: all 0.3s ease-in-out;
    font-family: "Nunito Sans", sans-serif;
    background-color: var(--buttonColor) !important;
  }
  .home-img-row {
    width: 70%;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }
  .home-img-box {
    margin: 10px;
    width: 210px;
    height: 210px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: 500px;
    flex-direction: column;
    justify-content: center;
    border: #f48967 solid 5px;
    /* background-color: rgb(39, 17, 17); */
  }
  .home-img {
    object-fit: contain;
    width: 100% !important;
    height: 100% !important;
  }
  .home-img2-row {
    width: 90%;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .home-box-text {
    bottom: -26px;
    position: absolute;
    text-align: center;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .home-img3-row {
    width: 90%;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .home-img3-box {
    width: 30%;
    height: 190px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition-duration: 400ms;
    border: 2px solid rgb(155, 152, 152);
  }
  .input {
    width: 95%;
  }
  .borderTop {
    top: 0;
    width: 100%;
    position: absolute;
  }
  .borderBottom {
    bottom: 0;
    width: 100%;
    rotate: 180deg;
    position: absolute;
  }
  .home-img-row {
    width: 100%;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .home-img-box {
    width: 46%;
    height: 150px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition-duration: 400ms;
    border: 1px solid rgb(155, 152, 152);
  }
  .home-img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
  .home-img-box:hover {
    scale: 1.05;
    border: 2px solid var(--cardSecondaryColor);
  }
  .home-img2-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .home-img2-box {
    width: 46%;
    height: 140px;
    display: flex;
    cursor: pointer;
    margin-bottom: 50px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition-duration: 400ms;
    border: 1px solid rgb(155, 152, 152);
  }
  .home-img2-box:hover {
    scale: 1.05;
    border: 2px solid var(--cardSecondaryColor);
  }
  .home-img3-row {
    width: 90%;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .home-img3-box {
    width: 31%;
    height: 100px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition-duration: 400ms;
    border: 2px solid rgb(155, 152, 152);
  }
  .home-img3-box:hover {
    scale: 1.05;
    border: 2px solid var(--cardSecondaryColor);
  }
  iframe {
    width: 100%;
    height: 250px;
    border-radius: 10px;
  }
}
